import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import translation from "../../shared/utils/translation";
import TitlePrimary from "../../shared/components/text/TitlePrimary";
import {sectionMargin, subHeading} from "../../shared/utils/vars";
import {useEffect} from "react";
import ImageGrid from "../../shared/components/grid/ImageGrid";
import {selectedLanguage} from "../../shared/stores/ConfigStore";

const Wrapper = styled.div`
  overflow: hidden;
  margin-top: 48px;
`
const TitleWrapper = styled.div`
  text-align: center;
  margin: ${sectionMargin}
`

const AboutUsWrapper = () => {
  useEffect(() => {


  }, [] )

  const object = [
    {src: "/static/personnel/bobbie.jpg", style:{fontWeight: 600}, text:["Bobbie Oskarsson", "Key Account Manager / Partner", "Bobbie@toplednordic.se", `${selectedLanguage.get() === "en" ? "+46-721 777 180" : "0721-777 180"}`]},
    {src: "/static/personnel/emil.jpg", style:{fontWeight: 600}, text:["Emil Fremdling", "Product Manager / Partner", "Emil@toplednordic.se", `${selectedLanguage.get() === "en" ? "+46-721 777 977" : "0721-777 977"}`]},
    {src: "/static/personnel/thomas.jpg", style:{fontWeight: 600}, text:["Thomas Holmer", "Managing Director / Partner", "Thomas@toplednordic.se", `${selectedLanguage.get() === "en" ? "+46-704 943 653" : "0704-943 653"}`]},
    {src: "/static/personnel/therese.jpg", style:{fontWeight: 600}, text:["Therese Thörne", "Marketing", "Marketing@toplednordic.se", `${selectedLanguage.get() === "en" ? "+46-171 44 23 00" : "0171-44 23 00"}`]}
  ]

  return (
    <Wrapper>
      <TitleWrapper>
        <TitlePrimary>{translation(`title.whoarewe`)}</TitlePrimary>
      </TitleWrapper>
      <TitleWrapper>
        <TitlePrimary color={subHeading} small={true}>{translation(`who.paragraph`)}</TitlePrimary>
      </TitleWrapper>
      <ImageGrid object={object} imageStyle={{maxWidth: "250px"}} wrapperStyle={{marginBottom: "15px"}}/>
    </Wrapper>
  )
}

export default observer(AboutUsWrapper)