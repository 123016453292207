import React from "react"
import {observer} from "mobx-react-lite";
import styled from "styled-components"
import ImageGrid from "../../shared/components/grid/ImageGrid";
import TitlePrimary from "../../shared/components/text/TitlePrimary";
import translation from "../../shared/utils/translation";
import ParagraphBold from "../../shared/components/text/ParagraphBold";
import {selectedLanguage} from "../../shared/stores/ConfigStore";
import {subHeading} from "../../shared/utils/vars";

const WhoAreWeSectionWrapper = styled.section`
`

const TitleWrapper = styled.div`
  text-align: start;
  margin-bottom: 30px;
`

function WhoAreWeSection(){

  const object = [
    {src: "/static/personnel/bobbie.jpg", style:{fontWeight: 600}, text:["Bobbie Oskarsson", "Key Account Manager / Partner", "Bobbie@toplednordic.se", `${selectedLanguage.get() === "en" ? "+46-721 777 180" : "0721-777 180"}`]},
    {src: "/static/personnel/emil.jpg", style:{fontWeight: 600}, text:["Emil Fremdling", "Product Manager / Partner", "Emil@toplednordic.se", `${selectedLanguage.get() === "en" ? "+46-721 777 977" : "0721-777 977"}`]},
    {src: "/static/personnel/thomas.jpg",style:{fontWeight: 600}, text:["Thomas Holmer", "Managing Director / Partner", "Thomas@toplednordic.se", `${selectedLanguage.get() === "en" ? "+46-704 943 653" : "0704-943 653"}`]},
    {src: "/static/personnel/therese.jpg",style:{fontWeight: 600}, text:["Therese Thörne", "Marketing", "Marketing@toplednordic.se", `${selectedLanguage.get() === "en" ? "+46-171 44 23 00" : "0171-44 23 00"}`]}
  ]

  return (
    <WhoAreWeSectionWrapper>
      <TitleWrapper>
        <TitlePrimary color={subHeading} small={true}>{translation("title.whoarewe")}</TitlePrimary>
      </TitleWrapper>
      <ParagraphBold>{translation("who.paragraph")}</ParagraphBold>
      <ImageGrid object={object} imageStyle={{maxWidth: "250px"}} wrapperStyle={{marginBottom: "15px"}}/>
    </WhoAreWeSectionWrapper>
  )
}

export default observer(WhoAreWeSection)